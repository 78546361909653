
<script>
import { ref, onMounted, onUnmounted } from "vue";
import { http } from "@/http";
import { getMobileSystem, browser } from "@/utils";
import { useRoute } from "vue-router";
import router from "@/routes";
import copy from "copy-text-to-clipboard";
import { Toast } from "vant";
export default {
  setup() {
    let route = useRoute();
    let appDownloadUrl = ref("");
    let merchantPcAddr = ref("");
    let guideMaskVisible = ref(false);
    let intHandle = null;
    let appUrl = "";
    let iosUrl = "lpmerchant://";
    let androidUrl = "lanzu-merchant://";
    let ua = navigator.userAgent.toLowerCase();
    let ifUCorBaidu = ua.indexOf("baiduboxapp");
    let isIOS = getMobileSystem() == 'IOS'

    // if(route.query.status !== 'success') {
    //   router.replace('/')
    // }
    http
      .post(
        "/?cmd=com.xqxc.api.pigear.biz.platform.MerRegSuccessService.getMerRegSuccess",
        {
          systemType: getMobileSystem(),
        }
      )
      .then((res) => {
        if (res.result) {
          appDownloadUrl.value = res.result.appDownloadUrl;
          merchantPcAddr.value = res.result.merchantPcAddr;
        }
      });

    function handleOpenAndroidGuide(e) {
      if (browser.versions.weixin) {
        e.preventDefault();
        guideMaskVisible.value = true;
      } 
      // else {
      //   if (getMobileSystem() == "ANDROID") {
      //     openApp(androidUrl);
      //   }
      //   if (getMobileSystem() == "IOS") {
      //     window.location.href = appDownloadUrl.value; //下载链接
      //   }
      // }
    }

    function openApp(url) {
      var _clickTime = new Date().getTime();

      //启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
      if (ifUCorBaidu != -1) {
        Toast("该浏览器不支持直接打开app");
      } else {
        window.location.href = url; /***打开app的协议，有安卓同事提供***/
      }
      var _count = 0;
      intHandle = setInterval(function () {
        _count++;
        var elsTime = new Date().getTime() - _clickTime;
        if (_count >= 100 || elsTime > 4000) {
          clearInterval(intHandle);
          //检查app是否打开
          // if (
          //   document.hidden ||
          //   document.webkitHidden ||
          //   document.mozHidden ||
          //   document.msHidden
          // ) {
          //   // 打开了
          //   // alert("打开");
          //   window.location.href = url;
          //   // console.log('打开了')
          //   window.close();
          //   // return;
          // } else {
          //   // 没打开
          //   console.log("没打开");
          //   // alert('没打开')
          //   window.location.href = appDownloadUrl.value; //下载链接
          // }
          window.location.href = appDownloadUrl.value; //下载链接
        }
      }, 20);
    }

    const clickCopy = () => {
      if (!merchantPcAddr.value) return;
      copy(merchantPcAddr.value);
      Toast.success("链接复制成功");
    };

    function leavePage() {
      if (document.visibilityState === "hidden") {
        clearInterval(intHandle);
      }
    }

    // onMounted(() => {
    //   window.addEventListener("visibilitychange", leavePage());
    // });

    // onUnmounted(() => {
    //   window.removeEventListener("visibilitychange", leavePage());
    // });

    return {
      appDownloadUrl,
      merchantPcAddr,
      handleOpenAndroidGuide,
      guideMaskVisible,
      clickCopy,
      ifUCorBaidu,
      isIOS
    };
  },
};
</script>
<template>
  <div>
    <div class="page">
      <h3>恭喜您已成功成为猪耳朵商家</h3>
      <div>
        <p>猪耳朵商户版-pc</p>
        <p>
          <a :href="merchantPcAddr">{{ decodeURIComponent(merchantPcAddr) }}</a>
        </p>
        <p class="copy" @click="clickCopy">复制地址</p>
      </div>
      <div>
        <p>猪耳朵商户版-APP</p>
        <!-- <div v-if="true">下载猪耳朵商户版App</div>
        <div v-else>打开猪耳朵商户版App</div> -->
        <div class="open">
          <a :href="appDownloadUrl" @click="handleOpenAndroidGuide">下载猪耳朵商户版App</a>
        </div>
        <!-- <div class="open" v-if="ifUCorBaidu != -1">
          <p @click="handleOpenAndroidGuide">下 载猪耳朵商户版App</p>
        </div>
        <div class="open">
          <p @click="handleOpenAndroidGuide">下载猪耳朵商户版App</p>
        </div> -->
      </div>
    </div>
    <div
      v-show="guideMaskVisible"
      style="
        text-align: center;
        z-index: 1000;
        padding-left: 0.4rem;
        box-sizing: border-box;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #000;
        opacity: 0.7;
      "
    >
      <img src="@/assets/images/android-guide.png" />
    </div>
  </div>
</template>
<style lang="less" scoped>
.page {
  padding: 0.5rem;
  text-align: center;
}
.page h3 {
  margin: 0.5rem;
  font-size: 0.4rem;
}
.page p {
  margin: 0.5rem 0;
  word-break: break-word;
}
a {
  color: #999999;
}
.page {
  > div {
    text-align: left;
    background: #efefef;
    margin: 0.7rem 0;
    border-radius: 0.2rem;
    padding: 0.1rem 0.5rem;
  }
  .copy {
    color: #00abeb;
  }
  .open {
    width: 4.2rem;
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    border-radius: 0.4rem;
    background: rgba(22, 155, 213, 1);
    margin-bottom: 0.5rem;
    color: #fff;
    p {
      margin: 0;
    }
    a{
      display: block;
      color: #fff;
    }
  }
}
</style>